/* Font and General Styles */
@font-face {
    font-family: 'Gilroy';
    font-weight: medium;
    src: url('../../assets/fonts/Gilroy-Medium.ttf') format('truetype');
}

.about-us-div {
    margin-top: 5rem;
}

/* Layout and Flexbox */
.blob-section {
    display: flex;
    justify-content: center;
    gap: 3rem;
    color: #fff;
    overflow: hidden;
}

.about-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
}

.about-glow {
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(90deg, #B338FF 0%, #675EFF 100%);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient-animation 5s ease infinite;
}

.welcome-txt {
    font-size: 1.7rem;
}

.blob-content-section {
    position: relative;
    padding: 1.5rem;
    width: 400px;
    height: 500px;
    overflow: hidden;
}

.blob-content-section::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%) scale(0.5);
    animation: scaleAnimation 3s infinite;
    z-index: -1;
}

.badge-image {
    width: 60px;
    height: 60px;
}

.blob-right::before, .blob-center::before, .blob-left::before {
    background-size: 400px 400px;
}

.blob-right::before {
    background-image: url("../../assets/blob.svg");
}

.blob-center::before {
    background-image: url("../../assets/center_blob.svg");
}

.blob-left::before {
    background-image: url("../../assets/leftBlob.svg");
}

.blob-right, .blob-center, .blob-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
}

.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 1;
}

.text-section p, .text-section h2 {
    text-align: center;
}

.text-section p {
    width: 80%;
    font-size: 20px;
    font-weight: 400;
}

/* Keyframes */
@keyframes scaleAnimation {
    0%, 100% {
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        transform: translate(-50%, -50%) scale(1.08);
    }
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .blob-section {
        gap: 0;
    }

    .blob-content-section {
        height: auto;
        padding: 1rem;
    }

    .blob-right, .blob-center, .blob-left {
        min-height: 400px;
    }

    .blob-right::before, .blob-center::before, .blob-left::before {
        background-size: 300px 300px;
    }

    .text-section p, .text-section h2 {
        width: 60%;
        font-size: smaller;
    }

    .badge-image {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 1050px) {
    .blob-content-section {
        width: 300px;
    }
}

@media (max-width: 930px) {
    .text-section {
        flex-direction: column-reverse;
    }

    .blob-right::before, .blob-center::before, .blob-left::before {
        background-size: 290px 290px;
    }

    .text-section p {
        width: 60%;
        font-size: medium;
    }
}

@media (max-width: 850px) {
    .blob-section {
        flex-direction: column;
        align-items: center;
    }
}

.square, .triangle {
    position: absolute;
}


@media (max-width: 500px) {
    .text-section h2{
        font-size: 1.5rem;
    }
    .text-section p{
        font-size: small;
    }
}

