.review-div{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 5rem;
}
.review-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
}
.left-section, .right-section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.4rem;
}
.left-section{
    width: 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #919191; */
}
.customer-img{
    width: 100%;
    display: flex;
}
.left-section .customer-img:nth-child(2){
    /* margin-left: 20rem; */
    justify-content: end;
}
.left-section .customer-img:nth-child(odd){
    /* margin-left: 7rem; */
    justify-content: center;
}
.center-section {
    position: relative; /* Required to position the pseudo-element */
    width: 40%;
    display: flex;
    height: fit-content;
    background: linear-gradient(90.78deg, #000000 0.61%, #675EFF 51.95%, #FF234A 99.33%);
    z-index: 1; /* Ensure it appears above the pseudo-element */
}

.center-section::before {
    content: '';
    position: absolute;
    top: -4px; /* Adjust to control border width */
    right: -4px;
    bottom: -4px;
    left: -4px;
    background: linear-gradient(90.78deg, #000000 0.61%, #675EFF 51.95%, #FF234A 99.33%);
    z-index: -1; /* Place it behind the .center-section */
    border-radius: 8px; /* Optional: Apply border radius if needed */
}

.center-content-div {
    z-index: 2; /* Ensure the content is above the border pseudo-element */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    gap: 2rem;
    background-color: white;
}
.center-content-div p{
    text-align: center;
    color: #919191;
}
.right-section{
    width: 30%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* background-color: #919191; */
}

.right-section .customer-img:nth-child(odd){
    display: flex;
    justify-content: center;
}

@media (max-width: 1030px) {
    .review-section{
        gap: 2rem;
    }
    .left-section, .right-section{
        width: 25%;
    }
    .center-section{
        width: 60%;
    }
    .left-section .customer-img:nth-child(2){
        /* margin-left: 20rem; */
        justify-content: flex-end;
    }
    .left-section .customer-img:nth-child(odd){
        /* margin-left: 7rem; */
        justify-content: start;
    }
    .right-section .customer-img:nth-child(odd){
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 650px) {
    .left-section, .right-section{
        display: none;
    }
    .review-section{
        justify-content: center;
    }
    .center-section{
        width: 80%;
    }
}