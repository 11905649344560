@font-face {
    font-family: 'Gilroy';
    font-weight: medium;
    src: url('../../../assets/fonts/Gilroy-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-weight: medium;
    src: url('../../../assets/fonts/Gilroy-Bold.ttf') format('truetype');
}



.icon-section{
    width: 60px;
    height: 60px;
    background: linear-gradient(180deg, #9747FF 0%, #675EFF 100%);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-card{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    justify-content: center ;
    align-items: center;
    text-align: center;
    height: 500px;
    width: 500px;
    background-color: white;
    z-index: 1 !important;
    box-shadow: 0px 2.32px 18.53px 0px #00000026;
}
.card-text-section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.card-text-section h3{
    font-size: 20px;
    font-weight: bold;
}
.card-text-section p{
    color: #919191;
    font-weight: medium !important;
}

@media (max-width: 1850px){
    .my-card{
        width: 350px;
        height: 350px;
    }
}
@media (max-width: 1600px){
    .my-card{
        width: 300px;
        height: 300px;
    }
}
@media (max-width: 750px){
    .my-card{
        width: 250px;
        height: 250px;
        gap: 1rem;
    }
    .card-text-section p{
        font-size: 13px;
    }
}