/* styles.css */
.socials-div {
    margin-top: 5rem;
    position: relative;
    padding: 2rem;
}

.about-top {
    text-align: center;
    margin-bottom: 3rem;
}

.about-glow {
    font-size: 2rem;
    color: #333;
}

.welcome-txt h1 {
    font-size: 2.5rem;
    color: #222;
}

.social-links1, .social-links {
    display: flex;
    justify-content: center;
    padding: 3rem;
}

.social-links {
    border: 1px solid #919191;
    border-radius: 1rem;
    gap: 2rem;
    background-color: white;
    box-shadow: 0px 2px 30px 0px #0000001A; 
}

.card {
    display: flex;
    gap: 1rem;
}

.followus {
    width: fit-content;
    background: linear-gradient(90deg, #000000 0%, #675EFF 50%, #332EFF 74.4%, #FF234A 95.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-holder {
    padding: 0.5rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
}

.social-links .card-holder:not(:last-child) {
    border-right: 1px solid #919191;
}

/* Responsive Styles */
@media (max-width: 980px) {

    .social-links1, .social-links {
        padding: 1.5rem;
    }
    .social{
        gap: none;
    }
    
    .card-holder {
        padding: 0.5rem 1rem;
    }
    .social h2, .social h3{
        font-size: 1rem;
    }
    .social-img{
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 760px) {

    .social-links1, .social-links {
        padding: 0.5rem;
    }
    .social{
        gap: none;
    }
    
    .card-holder {
        padding: 0.5rem;
    }
    .social h2, .social h3{
        font-size: 0.7rem;
    }
    .social-img{
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 600px) {
    .socials-div{
        display: none;
    }
}
