.info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    width: 150px;
}
.profile-img{
    width: 150px;
    height: 150px;
}
.emps-img{
    width: 100px;
    height: 100px;
}
.image-section{
    display: flex;
    flex-direction: column;
}
.name-desigantion{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name-desigantion p{
    font-size: 20px;
    width: max-content;
}
.name-desigantion h2{
    width: max-content;
    font-size: 20px !important;
}
.designation{
    font-size: 30px !important;
    background: linear-gradient(90deg, #000000 0%, #675EFF 50%, #332EFF 74.4%, #FF234A 95.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

@media (max-width: 1100px) {
    .profile-img{
        height: 120px;
        width: 120px;
    }
    .emps-img{
        width: 80px;
        height: 80px;
    }
    .name-desigantion h2, .name-desigantion p{
        font-size: 15px !important;
    }
}