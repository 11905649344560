
@font-face {
    font-family: 'Gilroy';
    font-weight: medium;
    src: url('../../assets/fonts/Gilroy-Medium.ttf') format('truetype');
}



.services-div{
    overflow: hidden;
    margin-top: 5rem;
}
.services{
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;
}
.card1, .card2, .card3, .card4{
    position: relative;
}

@media (max-width: 1850px){
    .services{
        gap: 2rem;
    }
}
@media (max-width: 1600px){
    .services{
        gap: 1rem;
    }
}
@media (max-width: 1350px){
    .services{
        gap: 3rem 6rem;
        justify-content: center;
    }
}
@media (max-width: 1250px){
    .services{
        gap: 3rem;
        justify-content: space-evenly;
    }
}