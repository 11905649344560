.footer-div{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-text{
    width: 60%;
    text-align: center;
    font-size: 20px;
    color: #919191;
}
.social-images{
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}
.mail-location{
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}
.mail a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}
.locations{
    display: flex;
    gap: 1rem;
}
.location{
    border: 1px solid #919191;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 5rem;
    display: flex;
    gap: 0.5rem;
}
.copyrights{
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
}
.copyrights p{
    font-weight: bold;
    font-size: 18px;
}
@media (max-width: 500px){
    .footer-text{
        font-size: 15px;
    }
    .mail-location{
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .copyrights p{
        font-size: 10px;
        width: 80%;
        text-align: center;
    }
}