.our-team{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 3rem;
    overflow: hidden;
    margin-top: 5rem;
}
.top-ceo{
    display: none;
}
.our-team-top{
    display: flex;
}
.team-imgs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
}
.top-imgs{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10rem;
}
.team-text{
    font-size: 20px;
    text-align: center;
    width: 58%;
    color: #919191;
}
/* Simplified Example using Flex Properties */
.mid-imgs, .low-imgs{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7rem;
    flex-wrap: wrap;
}
.left-section-emps {
    
}

.right-section-emps {
    
}

@media (max-width: 1430px) {
    .mid-imgs, .low-imgs{
        gap: 6rem;
    }
}
@media (max-width: 1380px) {
    .mid-imgs, .low-imgs{
        gap: 4rem;
    }
}
@media (max-width: 1210px) {
    .mid-imgs, .low-imgs{
        gap: 2.5rem;
    }
}
@media (max-width: 1100px) {
    .top-imgs{
        gap: 4rem;
    }
    .mid-imgs, .low-imgs{
        gap: 1rem;
    }
}

@media (max-width: 600px) {
    .top-ceo{
        display: block;
    }
    .middle-ceo{
        display: none;
    }
    .top-imgs{
        align-items: center;
        flex-direction: column;
    }
    .team-text p{
        width: 100%;
        font-size: 15px;
    }
}
