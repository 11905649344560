@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
/* @import url("./assets/fonts/Gilroy-Bold.ttf"); */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* body{
  font-family: "Inria Sans", sans-serif;
} */

@font-face {
  font-family: 'Gilroy';
  font-weight: bold;
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
}

/* Apply the font globally */
body {
  font-family: 'Gilroy', sans-serif;
  font-weight: bold;
}

.app{
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 5rem;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #B338FF 0%, #675EFF 100%);
  border-radius: 1rem;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
