.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky !important;
  top: 0;
  left: 0;
  padding: 0rem 2rem;
  box-shadow: 0px 2px 15px 0px #0000000d;
  backdrop-filter: blur(10px) !important;
  z-index: 10;
  height: 50px;
  padding: 2.5rem;
  background-color: white;
}
.gotoz-logo {
  width: 150px;
}
.logo {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.nav ul {
  display: flex;
  list-style-type: none;
}
.nav ul li {
  margin: 0 15px;
  cursor: pointer;
  font-size: 20px;
}
.nav-links {
  text-decoration: none;
  color: #000;
}
.nav-links.active {
  color: #b338ff; /* Change the color as needed */
  border-bottom: 4px solid #b338ff;
}
.contact-button {
  background: linear-gradient(90deg, #b338ff 0%, #675eff 100%);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 12px 0px #675eff;
}
.contact-button:hover {
  background-color: #3700b3;
}
.hamburger-menu {
  display: none;
}
.overlay ul li {
  list-style: none;
}
@media (max-width: 610px) {
  .nav-links {
    display: none;
  }
  .gotoz-logo{
    width: 100px;
  }
}
