/* Particle styles */
/* .particle {
  position: absolute;
  background-color: #B338FF;
  border-radius: 50%;
  pointer-events: none;
  animation: moveParticle 10s linear infinite;
}

@keyframes moveParticle {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-800px) translateX(calc(-200px + 400px * var(--direction)));
    opacity: 0;
  }
} */

/* Ensure particles are within the hero section */
/* .hero-section {
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-hero {
  width: 60%;
}

.right-hero {
  padding: 1rem;
  width: 40%;
}

.left-hero, .right-hero {
  position: relative;
}

.left-hero {
  animation: moveFromLeft 1s forwards;
}

.right-hero {
  animation: moveFromRight 1s forwards;
}

@keyframes moveFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.smallred{
  position: absolute;
  top: 5rem;
  left: 30rem;
}

.background-ball{
  z-index: -2 !important;
}

@media (max-width: 950px) {
  .left-hero{
    width: 40%;
  }
  .right-hero{
    width: 60%;
  }
}

@media (max-width: 850px) {
  .hero-section{
    flex-direction: column;
  }
  .left-hero, .right-hero{
    width: 100%;
  }
} */



.hero-section{
  width: 100%;
  position: relative;
}
.left-hero, .right-hero{
  width: 100%;
  height: 100vh !important;
  position: absolute;
  top: 0;
  left: 0;
}
.left-hero{
  z-index: 1;
}
.right-hero{
  z-index: -2;
}


.hero-section-new {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-section-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.magic {
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #c2c2c2;
  border-radius: 3rem;
  padding: 0.2rem 0.3rem;
  width: fit-content;
  box-shadow: 0px 2.41px 18.04px 0px #00000026;
}

.head-gradient {
 
  font-weight: 400px;
  font-size: 40px;
  position: relative;
  background: linear-gradient(
    90deg,
    #000000 0%,
    #675eff 50%,
    #332eff 74.4%,
    #ff234a 95.9%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  display: flex;
}

.hero-products {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hero-product-1,
.hero-product-3,
.hero-product-5 {
  height: 500px;
  width: 230px;
  object-fit: contain;
}

.hero-product-2,
.hero-product-4 {
  height: 440px;
  width: 200px;
  background-color: #c2c2c2;
}

.hero-product-1 img,
.hero-product-2 img,
.hero-product-3 img,
.hero-product-2 img,
.hero-product-5 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-products {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hero-product-1,
.hero-product-2,
.hero-product-3,
.hero-product-4,
.hero-product-5 {
  position: relative;
  overflow: hidden; 
}

.hero-product-1:hover .hover-text,
.hero-product-2:hover .hover-text,
.hero-product-3:hover .hover-text,
.hero-product-4:hover .hover-text,
.hero-product-5:hover .hover-text {
  display: block;
}

.hover-text {
  display: none;
  position: absolute;
  bottom: 10px; 
  left: 10px; 
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.image-container {
  position: relative;
  overflow: hidden; 
}

.hover-text {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px; 
  border-radius: 5px;
  font-size: 14px;
  pointer-events: none; 
}

.image-container:hover .hover-text {
  display: flex; 
}

.image-container:hover img {
  filter: blur(5px); 
}

.hero-product-1 video,
.hero-product-2 video,
.hero-product-3 video,
.hero-product-4 video,
.hero-product-5 video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel-container {
  width: 80%;
  margin: 0 auto;
}

.video-slide {
  margin-top: 90px;
  margin-bottom: 40px;
 display: flex !important;
 flex-direction: column !important;
 align-items: center !important;
 gap: 6rem;
  transition: transform 0.3s ease-in-out; 
}

.center-slide {
  transform: scale(1.4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 8px 16px rgba(0, 0, 0, 0.1), 
              0 12px 24px rgba(0, 0, 0, 0.1), 
              0 16px 32px rgba(0, 0, 0, 0.1), 
              0 20px 40px rgba(0, 0, 0, 0.1);
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-slide video {
  border-radius: 5px;
  width: 100%;
  height: 99%; 
  object-fit: cover;
  object-position: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  width: 160px;
  border: 4px solid black !important;
  border-radius: 1rem;
  background-color: #f0f0f0;
}

.inner-span {
  position: absolute;
  border: 1px solid black;
  background-color: black;
  width: 5rem;
  height: 0.5rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  z-index: 2;
}
.typewritter-text{
  text-align: center;
  position: relative;
  background: linear-gradient(
    90deg,
    #000000 0%,
    #675eff 50%,
    #332eff 74.4%,
    #ff234a 95.9%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  display: flex;
  font-size: x-large !important;
  font-weight: bold !important;
}


.mycard {
  width: 210px;
  height: 400px;
  background: black;
  border-radius: 35px;
  border: 2px solid rgb(40, 40, 40);
  padding: 7px;
  position: relative;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.486);
  background-color: transparent;
}


.top {
  position: absolute;
  top: 0px;
  right: 50%;
  transform: translate(50%, 0%);
  width: 35%;
  height: 18px;
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.speaker {
  position: absolute;
  top: 2px;
  right: 50%;
  transform: translate(50%, 0%);
  width: 40%;
  height: 2px;
  border-radius: 2px;
  background-color: rgb(20, 20, 20);
}

.camera {
  position: absolute;
  top: 6px;
  right: 84%;
  transform: translate(50%, 0%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.048);
}
.btn1, .btn2, .btn3, .btn4 {
  position: absolute;
  width: 2px;
}

.btn1, .btn2, .btn3 {
  height: 45px;
  top: 30%;
  right: -4px;
  background-image: linear-gradient(to right, #111111, #222222, #333333, #464646, #595959);
}

.btn2, .btn3 {
  transform: scale(-1);
  left: -4px;
}

.btn2, .btn3 {
  transform: scale(-1);
  height: 30px;
}

.btn2 {
  top: 26%
}

.btn3 {
  top: 36%
}

.typewriter-text{
  text-align: center;
  position: relative;
  background: linear-gradient(
    90deg,
    #000000 0%,
    #675eff 50%,
    #332eff 74.4%,
    #ff234a 95.9%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

@media (max-width: 500px) {
  .container{
    width: 120px;
    height: 240px;
  }
  .hero-text-section h1{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}
