.product-div {
  overflow: hidden;
  margin-top: 5rem;
}
.features-img {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #675eff 0%, #332eff 100%);
  padding: 1.5rem;
  border-radius: 2rem;
}
.product-content-section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.key-features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.features-img img {
  width: 30px;
  height: 30px;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.list-desc {
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  gap: 2rem;
  background-color: white;
  box-shadow: 0px 2px 15px 0px #00000026;
  width: fit-content;
}

.list .list-desc:nth-child(even) {
  margin-left: 4rem;
}
.vr-list .list-desc:nth-child(even) {
  margin-left: 8rem;
}
.vr-web-link {
  display: flex;
  justify-content: end;
}
.product-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
}
.product-content-section {
  width: 50%;
  display: flex;
}
.product-title {
  position: relative;
  font-weight: 400px;
  text-align: center;
  font-size: 40px;
  background: linear-gradient(
    90deg,
    #000000 0%,
    #675eff 50%,
    #332eff 74.4%,
    #ff234a 95.9%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  display: flex;
}
.title-android {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  top: -0.5rem;
  width: 60px;
  height: 60px;
  left: 96%;
  border-radius: 3rem !important;
  backdrop-filter: blur(2px) !important;
}
.product-title-desc {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 18px;
  color: #919191;
}
.product-image-section {
  position: relative;
  width: 500px;
  height: 500px;
  padding: 1rem;
}
.product-image-section img {
  position: absolute;
  width: 70%;
  height: 70%;
}
.product-title3 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.left-iphone-1 {
  top: 3rem;
  left: 1rem;
  animation: translateYLeft 2s infinite ease-in-out;
  width: 60% !important;
  height: 70% !important;
  object-fit: contain;
  z-index: 3;
}
.right-iphone-1 {
  top: 2rem;
  right: 0;
  animation: translateYRight 2s infinite ease-in-out;
  object-fit: contain;
  height: 80% !important;
  /* width: 90% !important; */
}

.left-iphone-2 {
  left: 1rem;
  object-fit: contain;
  animation: translateYRight 2s infinite ease-in-out;
}
.right-iphone-2 {
  object-fit: contain;
  right: 1rem;
  animation: translateYLeft 2s infinite ease-in-out;
}
.list3 {
  align-items: center;
  justify-content: center;
}
.list-desc1 {
  width: 60%;
}
.list-desc2 {
  width: 55%;
}
.list-desc3 {
  width: 70%;
}
.list3 .list-desc3:nth-child(even) {
  margin-left: 10rem;
}
.list-desc4 {
  width: 50%;
}
.list-desc5 {
  width: 60%;
}
.key-features3 .connectou {
  display: flex;
  justify-content: center;
}
.red-blob {
  width: 100px !important;
  height: 100px !important;
  top: 0rem !important;
  z-index: -2;
  left: -2rem !important;
}
.bottom-blob {
  width: 35% !important;
  right: 1rem !important;
  bottom: 2rem !important;
  z-index: -2;
  height: fit-content !important;
}
.gradient-img-section {
    width: auto;
    height: auto;
}
.link-div {
  border: 1px solid #c2c2c2;
  border-radius: 3rem;
  width: max-content;
  padding: 0.3rem 1rem;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visit-link {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}
.ss1 {
  margin-top: 2rem;
}
.ss2 {
  margin-right: 8rem;
  width: 30% !important;
}
.parcel-blob {
  margin-top: 6rem;
  margin-left: 3rem;
  height: 70% !important;
  width: 70% !important;
}

@keyframes translateYLeft {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust this value to your liking */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes translateYRight {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px); /* Adjust this value to your liking */
  }
  100% {
    transform: translateY(0);
  }
}

.gap-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 1000px) {
  .product-content-section {
    width: 60% !important;
  }
  .product-image-section {
    width: 40%;
  }
  .list-desc {
    gap: 2rem;
  }
  .product-title {
    font-size: 30px;
  }
 .product-title3 {
    font-size: 25px;
  }
 .list-desc1 {
    width: 50%;
  }
 .list-desc2 {
    width: 45%;
  }
 .list-desc3 {
    width: 60%;
  }
 .list-desc4 {
    width: 40%;
  }
 .list-desc5 {
    width: 50%;
  }
  .product-title-desc {
    font-size: 16px;
  }
}
@media (max-width: 800px){
    .st-product-section{
        flex-direction: column;
    }
    .ar-vr-section, .connect-ou-section{
        flex-direction: column-reverse;
    }
    .list-desc1, .list-desc2, .list-desc3, .list-desc4, .list-desc5{
        width: 75%;
    }
    .st-img-section{
        width: 100%;
    }
    .product-image-section3-2{
        display: none;
    }
}
@media (max-width: 500px){
    .st-product-section{
        width: 100%;
        flex-direction: column;
    }
    .product-title{
        font-size: 23px;
    }
    .st-para, .product-title-desc p{
        font-size: small;
        text-align: center;
    }
    .ar-vr-section, .connect-ou-section{
        flex-direction: column-reverse;
    }
    .list-desc1, .list-desc2, .list-desc3, .list-desc4, .list-desc5{
        width: 90%;
    }
    .st-img-section{
        width: 100%;
    }
    .product-image-section3-2{
        display: none;
    }
    .product-content-section {
        width: 80% !important;
    }
    .list-desc{
        gap: 1rem;
    }
    .list-desc p{
        font-size: small;
    }
    .features-img{
        padding: 15px;
    }
    .features-img img{
        width: 15px;
    }
    .gradient-img-section{
        display: none;
    }
    .vr-list .list-desc:nth-child(even){
        margin-left: 2rem;
    }
    .list3 .list-desc3:nth-child(even){
        margin-left: 2rem;
    }
    .list-desc4:nth-child(even){
        margin-left: -1rem !important;
    }
    .product-image-section2{
        display: none;
    }
    .ss1{
        left: 8rem;
    }
    .ss2{
        width: 150px !important;
        left: 1rem;
    }
}
